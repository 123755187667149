<template>
  <div>
    <h2 class="text-center">{{ $t("label.billingInformation") }}</h2>
    <a-form :form="form" @submit.prevent="submitForm">
      <a-row :gutter="24">
        <a-col :span="24" :xl="12">
          <div
            style="
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              padding: 30px 20px 20px 20px;
              margin-bottom: 20px;
            "
          >
            <h4 class="text-center">{{ $t("label.billingAddress") }}</h4>
            <a-form-item
              :label="$t('form.firstName')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.first_name"
                v-validate="'required|min:3|max:20'"
                type="text"
                class="form-control"
                name="first_name"
                data-vv-as="first name"
              />
              <div
                v-if="submitted && errors.has('first_name')"
                style="color: red"
              >
                <span
                  v-if="
                    errors.first('first_name') ==
                    'The first name field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
                <span
                  v-if="
                    errors.first('first_name') ==
                    'The first name field must be at least 3 characters.'
                  "
                  >{{ $t("rules.min3") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('form.lastName')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.last_name"
                v-validate="'required|min:3|max:20'"
                type="text"
                class="form-control"
                name="last_name"
                data-vv-as="last name"
              />
              <div
                v-if="submitted && errors.has('last_name')"
                style="color: red"
              >
                <span
                  v-if="
                    errors.first('last_name') ==
                    'The last name field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
                <span
                  v-if="
                    errors.first('last_name') ==
                    'The last name field must be at least 3 characters.'
                  "
                  >{{ $t("rules.min3") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              v-if="user.email"
              :label="$t('form.email')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.email"
                v-validate="'required|email|max:50'"
                type="email"
                class="form-control"
                name="email"
                data-vv-as="email"
              />
              <div style="color: red" v-if="submitted && errors.has('email')">
                <span
                  v-if="errors.first('email') == 'The email field is required.'"
                  >{{ $t("rules.enterEmail") }}</span
                >
                <span
                  v-else-if="
                    errors.first('email') ==
                    'The email field must be a valid email.'
                  "
                  >{{ $t("rules.emailForm") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              v-if="user.phone"
              :label="$t('form.phone')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <vue-tel-input
                id="phone"
                v-model="form.phone"
                v-bind="vueTel.props"
                v-validate="'required|min:8|max:25'"
                type="text"
                data-vv-rules="required"
                data-vv-as="Phone"
                name="phone"
              >
              </vue-tel-input>
              <div style="color: red">
                <span
                  v-if="errors.first('phone') == 'The Phone field is required.'"
                >
                  {{ $t("rules.requirePhone") }}
                </span>
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('form.address')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.address"
                v-validate="'required|min:3|max:100'"
                type="text"
                class="form-control"
                name="address"
                data-vv-as="address"
              />

              <div v-if="submitted && errors.has('address')" style="color: red">
                <span
                  v-if="
                    errors.first('address') == 'The address field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
                <span
                  v-if="
                    errors.first('address') ==
                    'The address field must be at least 3 characters.'
                  "
                  >{{ $t("rules.min3") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('form.city')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.city"
                v-validate="'required|min:3|max:50'"
                type="text"
                class="form-control"
                name="city"
                data-vv-as="city"
              />
              <div v-if="submitted && errors.has('city')" style="color: red">
                <span
                  v-if="errors.first('city') == 'The city field is required.'"
                  >{{ $t("rules.require") }}</span
                >
                <span
                  v-if="
                    errors.first('city') ==
                    'The city field must be at least 3 characters.'
                  "
                  >{{ $t("rules.min3") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('form.country')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-select
                v-model="form.country"
                @change="handleCountryChange"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="country"
                data-vv-as="country"
              >
                <a-select-option
                  v-for="country in countries"
                  :key="country.isoCode"
                  :value="country.isoCode"
                  >{{ country.name }}</a-select-option
                >
              </a-select>
              <div v-if="submitted && errors.has('country')" style="color: red">
                <span
                  v-if="
                    errors.first('country') == 'The country field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('form.stateProvince')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-select
                v-model="form.state"
                :disabled="!form.country"
                type="text"
                class="form-control"
                name="state"
                data-vv-as="state/province"
              >
                <a-select-option
                  v-for="state in states"
                  :key="state.isoCode"
                  :value="state.name"
                  >{{ state.name }}</a-select-option
                >
              </a-select>
              <div v-if="submitted && errors.has('state')" style="color: red">
                <span
                  v-if="
                    errors.first('state') ==
                    'The state/province field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
              </div>
            </a-form-item>
            <a-form-item
              :label="$t('label.zipPostal')"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
              required
            >
              <a-input
                v-model="form.zip"
                v-validate="'required|min:2|max:20'"
                type="text"
                class="form-control"
                name="zip_code"
                data-vv-as="zip code"
              />
              <div
                v-if="submitted && errors.has('zip_code')"
                style="color: red"
              >
                <span
                  v-if="
                    errors.first('zip_code') ==
                    'The zip code field is required.'
                  "
                  >{{ $t("rules.require") }}</span
                >
              </div>
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="24" :xl="12">
          <div
            style="
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              padding: 30px 20px 20px 20px;
              margin-bottom: 20px;
            "
          >
            <a-row :gutter="24">
              <a-col>
                <div
                  style="
                    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 1px;
                    padding: 20px;
                    text-align: center;
                    margin: 0 0 20px 0;
                  "
                >
                  <h4>{{ $t("label.paymentInformation") }}</h4>
                  <!-- <hr class="mb-25" /> -->
                  <a-form-item
                    :label="$t('form.paymentType')"
                    :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 14 }"
                    required
                  >
                    <a-select
                      v-model="form.cardType"
                      v-validate="'required'"
                      type="text"
                      class="form-control"
                      name="payment_type"
                      data-vv-as="card type"
                    >
                      <a-select-option
                        v-for="payment in payments"
                        :key="payment.value"
                        :value="payment.value"
                      >
                        <a-avatar
                          :src="payment.logo"
                          shape="square"
                          size="small"
                        />
                        {{ payment.label }}
                      </a-select-option>
                    </a-select>
                    <div
                      v-if="submitted && errors.has('payment_type')"
                      style="color: red; text-align: left"
                    >
                      <span
                        v-if="
                          errors.first('payment_type') ==
                          'The card type field is required.'
                        "
                        >{{ $t("rules.require") }}</span
                      >
                    </div>
                  </a-form-item>
                  <p style="color: red">
                    Remark: Support only debit/credit card with 3D secure
                  </p>
                  <a-form-item
                    :label="$t('form.cardNumber')"
                    :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 14 }"
                    required
                  >
                    <a-input
                      @input="onCardNumberInput"
                      v-model="form.cardNumber"
                      placeholder="xxxx xxxx xxxx xxxx"
                      v-validate="'required|min:19'"
                      class="form-control"
                      name="card_number"
                      data-vv-as="card number"
                    />
                    <div
                      v-if="submitted && errors.has('card_number')"
                      style="color: red; text-align: left"
                    >
                      <span
                        v-if="
                          errors.first('card_number') ==
                          'The card number field is required.'
                        "
                        >{{ $t("rules.require") }}</span
                      >
                      <span
                        v-if="
                          errors.first('card_number') ==
                          'The card number field must be at least 19 characters.'
                        "
                        >{{ $t("rules.min16") }}</span
                      >
                    </div>
                  </a-form-item>
                  <a-row :gutter="[16, 16]">
                    <a-col :span="12">
                      <a-form-item
                        :label="$t('form.expirationDate')"
                        :label-col="{ span: 15 }"
                        :wrapper-col="{ span: 9 }"
                        required
                      >
                        <a-month-picker
                          v-model="form.expirationDate"
                          :disabled-date="disabledDate"
                          :placeholder="''"
                          format="MM/YY"
                          v-validate="'required'"
                          type="text"
                          class="form-control"
                          name="expiration_date"
                          data-vv-as="expiration date"
                        />
                        <div
                          v-if="submitted && errors.has('expiration_date')"
                          style="color: red; text-align: left"
                        >
                          <span
                            v-if="
                              errors.first('expiration_date') ==
                              'The expiration date field is required.'
                            "
                            >{{ $t("rules.require") }}</span
                          >
                        </div>
                      </a-form-item></a-col
                    >
                    <a-col :span="12">
                      <a-form-item
                        :label="$t('form.cvv')"
                        :label-col="{ span: 6 }"
                        :wrapper-col="{ span: 8 }"
                        required
                      >
                        <a-input
                          @input="onCVVInput"
                          v-model="form.cvv"
                          v-validate="'required|min:3'"
                          class="form-control"
                          name="cvv"
                          data-vv-as="CVV"
                        />
                        <div
                          v-if="submitted && errors.has('cvv')"
                          style="color: red; text-align: left"
                        >
                          <div
                            v-if="submitted && errors.has('cvv')"
                            style="color: red; text-align: left"
                          >
                            <span
                              v-if="
                                errors.first('cvv') ==
                                'The CVV field is required.'
                              "
                              >{{ $t("rules.require") }}</span
                            >
                            <span
                              v-if="
                                errors.first('cvv') ==
                                'The CVV field must be at least 3 characters.'
                              "
                              >{{ $t("rules.min3") }}</span
                            >
                          </div>
                        </div>
                      </a-form-item></a-col
                    >
                  </a-row>
                </div>
              </a-col>
              <a-col>
                <div
                  style="
                    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 1px;

                    padding: 20px;
                    text-align: center;
                  "
                >
                  <span style="font-size: 24px">{{
                    $t("label.orderSummary")
                  }}</span>
                  <hr />
                  <div>
                    <h4>{{ $t("label.totalAmount") }}</h4>
                    <a-statistic
                      prefix="₭"
                      :precision="2"
                      v-bind:value="500000"
                      valueStyle=" font-family:'NotoSans'; font-size: 24px; margin-top:3px"
                    >
                    </a-statistic>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" html-type="submit">{{
          $t("button.next")
        }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import moment from "moment";
import CreditCardType from "credit-card-type";
import { Country, State } from "country-state-city";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    const user = this.$store.state.auth.user;
    return {
      form: {},
      user,
      countries: [],
      states: [],
      submitted: false,
      vueTel: {
        phone: "",
        props: {
          preferredCountries: ["LA", "CN", "TH", "VN"],
          mode: "international",
          required: true,
          validCharactersOnly: true,
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
      payments: [
        {
          label: "Visa",
          value: "visa",
          logo: "https://cdn.worldvectorlogo.com/logos/visa-4.svg",
        },
        {
          label: "Mastercard",
          value: "mastercard",
          logo: "https://cdn.worldvectorlogo.com/logos/mastercard-2.svg",
        },
        {
          label: "JCB",
          value: "jcb",
          logo: "https://upload.wikimedia.org/wikipedia/commons/4/40/JCB_logo.svg",
        },
        {
          label: "American Express",
          value: "american-express",
          logo: "https://cdn.worldvectorlogo.com/logos/american-express-1.svg",
        },
        {
          label: "UnionPay",
          value: "unionpay",
          logo: "https://cdn.worldvectorlogo.com/logos/union-pay.svg",
        },
      ],
    };
  },
  mounted() {
    this.setFormValues();
    this.countries = Country.getAllCountries();
  },
  computed: {
    ...mapState("billing", ["billingData"]),
  },

  methods: {
    setFormValues() {
      this.form = { ...this.form, ...this.user };
      // this.form.setFieldsValue(this.form);
    },

    handleCountryChange(value) {
      if (this.form.state) {
        this.form.state = null;
      }
      this.states = State.getStatesOfCountry(value);
    },

    disabledDate(current) {
      return current && current < moment().endOf("day");
    },
    onCardNumberInput() {
      // detect the card type and update the card type variable
      const cardType = CreditCardType(this.form.cardNumber)[0];
      //console.log("cardType:", cardType);
      this.form.cardType = cardType ? cardType.type : "Invalid Card";

      this.form.cardNumber = this.form.cardNumber.replace(/\D/g, "");
      const formattedCreditCard = this.form.cardNumber.match(/.{1,4}/g);
      if (formattedCreditCard && cardType) {
        this.form.cardNumber = formattedCreditCard.join(" ");
        this.form.cardNumber = this.form.cardNumber.slice(
          0,
          cardType.lengths[0] + 3
        );
      } else {
        this.form.cardNumber = this.form.cardNumber.slice(0, 0);
      }

      return cardType;
    },
    onCVVInput() {
      this.form.cvv = this.form.cvv.replace(/\D/g, "");
      const cvvLength = this.onCardNumberInput();
      if (cvvLength) {
        this.form.cvv = this.form.cvv.slice(0, cvvLength.code.size);
      } else {
        this.form.cvv = this.form.cvv.slice(0, 0);
      }
    },
    ...mapActions("billing", ["updateBilling"]),
    submitForm() {
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.form.cardNumber = this.form.cardNumber.replace(/\s/g, "");
          this.updateBilling(this.form);
          console.log("this.form:", this.form);
          return this.$router.push(`/payment/202304-00005/confirm`);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  padding: 8px 0 8px 0;
  font-size: 20px;
}
</style>
