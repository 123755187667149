var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("label.billingInformation")))]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24,"xl":12}},[_c('div',{staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.24) 0px 3px 8px","padding":"30px 20px 20px 20px","margin-bottom":"20px"}},[_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("label.billingAddress")))]),_c('a-form-item',{attrs:{"label":_vm.$t('form.firstName'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:20'),expression:"'required|min:3|max:20'"}],staticClass:"form-control",attrs:{"type":"text","name":"first_name","data-vv-as":"first name"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),(_vm.submitted && _vm.errors.has('first_name'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('first_name') ==
                  'The first name field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                  _vm.errors.first('first_name') ==
                  'The first name field must be at least 3 characters.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min3")))]):_vm._e()]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.lastName'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:20'),expression:"'required|min:3|max:20'"}],staticClass:"form-control",attrs:{"type":"text","name":"last_name","data-vv-as":"last name"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),(_vm.submitted && _vm.errors.has('last_name'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('last_name') ==
                  'The last name field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                  _vm.errors.first('last_name') ==
                  'The last name field must be at least 3 characters.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min3")))]):_vm._e()]):_vm._e()],1),(_vm.user.email)?_c('a-form-item',{attrs:{"label":_vm.$t('form.email'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email|max:50'),expression:"'required|email|max:50'"}],staticClass:"form-control",attrs:{"type":"email","name":"email","data-vv-as":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.submitted && _vm.errors.has('email'))?_c('div',{staticStyle:{"color":"red"}},[(_vm.errors.first('email') == 'The email field is required.')?_c('span',[_vm._v(_vm._s(_vm.$t("rules.enterEmail")))]):(
                  _vm.errors.first('email') ==
                  'The email field must be a valid email.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.emailForm")))]):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.user.phone)?_c('a-form-item',{attrs:{"label":_vm.$t('form.phone'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('vue-tel-input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|max:25'),expression:"'required|min:8|max:25'"}],attrs:{"id":"phone","type":"text","data-vv-rules":"required","data-vv-as":"Phone","name":"phone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},'vue-tel-input',_vm.vueTel.props,false)),_c('div',{staticStyle:{"color":"red"}},[(_vm.errors.first('phone') == 'The Phone field is required.')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("rules.requirePhone"))+" ")]):_vm._e()])],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.$t('form.address'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:100'),expression:"'required|min:3|max:100'"}],staticClass:"form-control",attrs:{"type":"text","name":"address","data-vv-as":"address"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),(_vm.submitted && _vm.errors.has('address'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('address') == 'The address field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                  _vm.errors.first('address') ==
                  'The address field must be at least 3 characters.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min3")))]):_vm._e()]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.city'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:50'),expression:"'required|min:3|max:50'"}],staticClass:"form-control",attrs:{"type":"text","name":"city","data-vv-as":"city"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),(_vm.submitted && _vm.errors.has('city'))?_c('div',{staticStyle:{"color":"red"}},[(_vm.errors.first('city') == 'The city field is required.')?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                  _vm.errors.first('city') ==
                  'The city field must be at least 3 characters.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min3")))]):_vm._e()]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.country'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"type":"text","name":"country","data-vv-as":"country"},on:{"change":_vm.handleCountryChange},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country.isoCode,attrs:{"value":country.isoCode}},[_vm._v(_vm._s(country.name))])}),1),(_vm.submitted && _vm.errors.has('country'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('country') == 'The country field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e()]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.stateProvince'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 }}},[_c('a-select',{staticClass:"form-control",attrs:{"disabled":!_vm.form.country,"type":"text","name":"state","data-vv-as":"state/province"},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}},_vm._l((_vm.states),function(state){return _c('a-select-option',{key:state.isoCode,attrs:{"value":state.name}},[_vm._v(_vm._s(state.name))])}),1),(_vm.submitted && _vm.errors.has('state'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('state') ==
                  'The state/province field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e()]):_vm._e()],1),_c('a-form-item',{attrs:{"label":_vm.$t('label.zipPostal'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:2|max:20'),expression:"'required|min:2|max:20'"}],staticClass:"form-control",attrs:{"type":"text","name":"zip_code","data-vv-as":"zip code"},model:{value:(_vm.form.zip),callback:function ($$v) {_vm.$set(_vm.form, "zip", $$v)},expression:"form.zip"}}),(_vm.submitted && _vm.errors.has('zip_code'))?_c('div',{staticStyle:{"color":"red"}},[(
                  _vm.errors.first('zip_code') ==
                  'The zip code field is required.'
                )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e()]):_vm._e()],1)],1)]),_c('a-col',{attrs:{"span":24,"xl":12}},[_c('div',{staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.24) 0px 3px 8px","padding":"30px 20px 20px 20px","margin-bottom":"20px"}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',[_c('div',{staticStyle:{"box-shadow":"rgba(3, 102, 214, 0.3) 0px 0px 0px 1px","padding":"20px","text-align":"center","margin":"0 0 20px 0"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("label.paymentInformation")))]),_c('a-form-item',{attrs:{"label":_vm.$t('form.paymentType'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"type":"text","name":"payment_type","data-vv-as":"card type"},model:{value:(_vm.form.cardType),callback:function ($$v) {_vm.$set(_vm.form, "cardType", $$v)},expression:"form.cardType"}},_vm._l((_vm.payments),function(payment){return _c('a-select-option',{key:payment.value,attrs:{"value":payment.value}},[_c('a-avatar',{attrs:{"src":payment.logo,"shape":"square","size":"small"}}),_vm._v(" "+_vm._s(payment.label)+" ")],1)}),1),(_vm.submitted && _vm.errors.has('payment_type'))?_c('div',{staticStyle:{"color":"red","text-align":"left"}},[(
                        _vm.errors.first('payment_type') ==
                        'The card type field is required.'
                      )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e()]):_vm._e()],1),_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Remark: Support only debit/credit card with 3D secure ")]),_c('a-form-item',{attrs:{"label":_vm.$t('form.cardNumber'),"label-col":{ span: 7 },"wrapper-col":{ span: 14 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:19'),expression:"'required|min:19'"}],staticClass:"form-control",attrs:{"placeholder":"xxxx xxxx xxxx xxxx","name":"card_number","data-vv-as":"card number"},on:{"input":_vm.onCardNumberInput},model:{value:(_vm.form.cardNumber),callback:function ($$v) {_vm.$set(_vm.form, "cardNumber", $$v)},expression:"form.cardNumber"}}),(_vm.submitted && _vm.errors.has('card_number'))?_c('div',{staticStyle:{"color":"red","text-align":"left"}},[(
                        _vm.errors.first('card_number') ==
                        'The card number field is required.'
                      )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                        _vm.errors.first('card_number') ==
                        'The card number field must be at least 19 characters.'
                      )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min16")))]):_vm._e()]):_vm._e()],1),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.expirationDate'),"label-col":{ span: 15 },"wrapper-col":{ span: 9 },"required":""}},[_c('a-month-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"disabled-date":_vm.disabledDate,"placeholder":'',"format":"MM/YY","type":"text","name":"expiration_date","data-vv-as":"expiration date"},model:{value:(_vm.form.expirationDate),callback:function ($$v) {_vm.$set(_vm.form, "expirationDate", $$v)},expression:"form.expirationDate"}}),(_vm.submitted && _vm.errors.has('expiration_date'))?_c('div',{staticStyle:{"color":"red","text-align":"left"}},[(
                            _vm.errors.first('expiration_date') ==
                            'The expiration date field is required.'
                          )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e()]):_vm._e()],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.cvv'),"label-col":{ span: 6 },"wrapper-col":{ span: 8 },"required":""}},[_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],staticClass:"form-control",attrs:{"name":"cvv","data-vv-as":"CVV"},on:{"input":_vm.onCVVInput},model:{value:(_vm.form.cvv),callback:function ($$v) {_vm.$set(_vm.form, "cvv", $$v)},expression:"form.cvv"}}),(_vm.submitted && _vm.errors.has('cvv'))?_c('div',{staticStyle:{"color":"red","text-align":"left"}},[(_vm.submitted && _vm.errors.has('cvv'))?_c('div',{staticStyle:{"color":"red","text-align":"left"}},[(
                              _vm.errors.first('cvv') ==
                              'The CVV field is required.'
                            )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.require")))]):_vm._e(),(
                              _vm.errors.first('cvv') ==
                              'The CVV field must be at least 3 characters.'
                            )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.min3")))]):_vm._e()]):_vm._e()]):_vm._e()],1)],1)],1)],1)]),_c('a-col',[_c('div',{staticStyle:{"box-shadow":"rgba(3, 102, 214, 0.3) 0px 0px 0px 1px","padding":"20px","text-align":"center"}},[_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.$t("label.orderSummary")))]),_c('hr'),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("label.totalAmount")))]),_c('a-statistic',{attrs:{"prefix":"₭","precision":2,"value":500000,"valueStyle":" font-family:'NotoSans'; font-size: 24px; margin-top:3px"}})],1)])])],1)],1)])],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.$t("button.next")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }